
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root {
  /* colors */
  --primary-color: #6460aa;
  --secondary-color: #eb1996;
  --background-color: #00adef;
  --color-green: #94dd46;
  --border-color: #0101011a;
  --color-black: #020202;
  --color-grey: #6b6b6b;

  /* margin and padding */
  --margin-top: 1rem;
  --margin--bottom: 1rem;
  --margin-left: 1rem;
  --margin-right: 1rem;
  --padding-top: 1rem;
  --padding--bottom: 1rem;
  --padding-left: 1rem;
  --padding-right: 1rem;

  /* fonts */
  --primary-font: "Raleway", sans-serif;
  --secondary-font: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: szzz;
}

a{
  text-decoration: none;
  color: black;
}

.LazyLoding{
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9999999999999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}

/* components */
.underLine {
  width: 120px;
  height: 4px;
  margin-top: -45px;
  right: -20px;
  border-radius: 12px;
  position: absolute;
  background-color: var(--secondary-color);
}
.underline {
  width: 22px;
  height: 3px;
  margin-top: -12px;
  /* left: 10%; */
  border-radius: 12px;
  position: absolute;
  background-color: var(--secondary-color);
}

/* nav components */

.navbar {
  border-bottom: 1px solid var(--border-color);
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1111;
}
.navbar .nav {
  display: flex;
  align-items: center;
  padding: 0rem 3rem;
  justify-content: space-between;
}

.navbar .nav ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
}

/* nav links */
.nav-link {
  transition: all ease-in-out 0.3s;
  text-decoration: none !important;
  color: var(--color-grey) !important;
  font-weight: 700;
  font-size: 12px;
  font-family: var(--primary-font);
  letter-spacing: 0.1rem;
}

.nav-link:hover {
  font-weight: 700;
  color: var(--primary-color) !important;
}
.activeMenu {
  color: var(--primary-color) !important;
  text-decoration: none;
  font-weight: 800;
  font-family: var(--primary-font);
  font-size: 12px;
  letter-spacing: 0.1rem;
}

/* social links */
.navbar .nav .navSocial ul {
  gap: 0.7rem;
  margin: 0;
}
.navbar .nav .navSocial ul li {
  cursor: pointer;
}

.navbar .nav .navSocial ul li img {
  width: 18px;
  aspect-ratio: 1;
  transition: all ease-in-out 0.3s;
}
.navbar .nav .navSocial ul li img:hover {
  transform: scale(1.2);
}

.sideWrapper{
  display: none;
}
/* nav end */

/* sideNav */
.sideNavbar{
  max-width: 300px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0px;
  padding: 0;
  border-radius: 12px 0px 0px 12px;
  transform: translateY(-100vh);
  transition: 0.5s ease all;
  background-color: var(--primary-color);
}
.sideOpen{
  transform: translateY(0vh);

}
.hamburgerOpen,.hamburgerClose{
  width: 30px;
  height: 30px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  background-color: var(--primary-color);
  color: #ffffff;
  font-family: var(--primary-font);
  font-weight: bold;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 15px;
  transition: all ease-in-out 0.3s;
}
.hamburgerClose{
  background-color: var(--secondary-color);
}

.sideNav .sideNavbar .sidenavItems{
  width: 100%;
}
.sideNav .sideNavbar .sidenavItems ol{
  margin: 5rem 0rem 1rem 0rem;
  list-style: none;
  padding-left: 0px;
}
.sideNav .sideNavbar .sidenavItems ol li{
  border-bottom: 2px solid #bdbdbd33;
  display: block;
  transition: all ease-in-out .3s;
}
.sideNav .sideNavbar .sidenavItems ol li:hover{
  border-bottom: 2px solid var(--secondary-color);
  background-color: var(--secondary-color);
}
.sideNav .sideNavbar .sidenavItems ol li a{
  color: #f7f7f7;
  display: block;
  padding: .7rem;
}
.socialIcons ul li img{
 cursor: pointer;
}

/* tab section */



.tabs{
  font-family:arial;
  margin:0 auto;
  text-align:center;
  margin-top:15vh;
  
}

.tabWrapper img{
  width:300px;
}

.tab-buttons{
  margin-bottom:10px;
  height: auto;
  display: flex;
  overflow-x: scroll;
  gap: 0;
  justify-content: center;
  border-bottom:2px solid var(--primary-color);
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}
.tab-buttons::-webkit-scrollbar {
  display: none;
}

.tab-buttons button{
  /* margin-right:15px; */
  margin-top: 12px;
  font-size:14px;
  background-color: #6460aaa9;
  border:none;
  font-family: var(--primary-font);
  border-radius: 0;
  outline:none;
  padding:1rem 2rem;
  width: auto;
  filter: none !important;
  cursor:pointer;
  height: auto;
  color:rgb(255, 255, 255);
  transition:all ease-in-out .5s;
  border-bottom:2px solid transparent;
  
}
@media screen and (max-width:600px) {
  .tab-buttons button{
    font-size:12px;
    padding:1rem 6px;
    
  }
}
.tab-buttons .active{
  /* border-bottom:2px solid #42b3f4; */
background: #6460aa;
color: #ffffff;
}



.contentWrapper {
  display: flex;
  justify-content: center ;
  gap: 2rem;
  transition:all ease-in-out .5s;
  margin:2rem;
  align-items: center !important;
}
.contentWrapper .imageWrapper{
  width: 50%;
}
.contentWrapper .textWrapper{
  width: 50%;
}
.contentWrapper .textWrapper h1{
  color: var(--primary-color);
  font-family: var(--primary-font);
}
.contentWrapper .textWrapper p{
  text-align: justify;
  font-family: var(--secondary-font);
}
.contentWrapper .imageWrapper img{
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
}

.whatWeDo{
  flex-direction: column;
}
.whatWeDo .imageWrapper{
  width: 70%;
}
.whatWeDo .imageWrapper img{
width: 100%;
/* min-height: 600px; */
border-radius: 6px;
box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
}
.whatWeDo .textWrapper{
  width: 70%;
}

.ourTeamTab{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin:  2rem;
}

.ourTeamTab .ourTeam-Wrapper h1{
  color: var(--primary-color);
  text-align: center;
  font-family: var(--primary-font);
}
.ourTeamTab .ourTeam-Wrapper p{
  color: var(--primary-color);
  text-align: center;
  font-family: var(--secondary-font);
}
.ourTeamTab .ourTeamCardSection{
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}
.ourTeamTab .ourTeamCardSection .ourTeamCard{
  min-width: 200px;
  aspect-ratio: 1;
  border-radius: 12px;
  box-shadow:0 0 10px rgba(0, 0, 0, 0.25);
}
.ourTeamTab .ourTeamCardSection .ourTeamCard .teamImage{
  width: 100%;
}
.ourTeamTab .ourTeamCardSection .ourTeamCard .teamImage img{
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: 200px;
  object-fit: fill;
}
.ourTeamTab .ourTeamCardSection .ourTeamCard .teamLabel{
 width: 100%;
 text-align: center;
}
.ourTeamTab .ourTeamCardSection .ourTeamCard .teamLabel h1{
 font-size: 18px;
 font-family: var(--primary-font);
 color: #6460aa;
}
.ourTeamTab .ourTeamCardSection .ourTeamCard .teamLabel p{
  font-size: 13px;
  margin-top: -10px;
  font-family: var(--secondary-font);
  color: #6460aa;
 }





button{
  width: 120px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-family: var(--primary-font);
  font-weight: bold;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
  height: 40px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
button:hover{
  background: linear-gradient(#ff40b2, #fb6bc0, #f063b7);
  color: #ffffff;
}









/* error page */

.errorPage{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-repeat:no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('./assets/error.gif');
  z-index: 9999999999;
}
.errorPage::after{
position: absolute;
content: '';
height: 100%;
width: 100%;
background-color: #f063b85d;
}
.errorContent {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
}
.errorPage .errorContent h1{
  color: #ffffff;
  font-size: 64px;
  font-family: var(--secondary-font);
  
}
.errorPage .errorContent h2{
  color: #ffffff;
  font-size: 22px;
  margin-top: -2rem;
  font-family: var(--secondary-font);
  
}
.errorPage .errorContent button{
  width: 120px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-family: var(--primary-font);
  font-weight: bold;
  filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.25));
  height: 40px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.errorPage .errorContent button:hover{
  background: linear-gradient(#ff40b2, #fb6bc0, #f063b7);
  color: #f7f7f7;
}

.errorPage .errorContent button:hover a{
  color: #f7f7f7;
}




/* footer wrappper */
.fotterWrapper {
  padding: 3rem 3rem 0rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  /* padding: 1rem 0rem 0rem; */
  background-color: var(--color-black);
  min-height: 100px;
  color: #ffffff;
}
.footerWidgets div p {
  text-align: justify;
  font-family: var(--secondary-font);
  font-size: 12px;
  color: #ffffff7d;
}
.footerWidgets div h1 {
  font-family: var(--primary-font);
  font-size: 16px;
}
.footerWidgets div ul {
  margin: 0;
  padding: 0;
  color: #ffffff7d;
  font-family: var(--secondary-font);
  font-size: 12px;
  /* padding-top: 1rem; */
  line-height: 2.3rem;
  list-style-type: none;
}
.footerWidgets div ul li a{
  color: #ffffff7d;

}
.footerWidgets div ul li {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all ease-in-out 0.3s;
  gap: 0.3rem;
}
.footerWidgets div ul li a:hover {
  color: var(--primary-color);
}
.footerWidgets div ul li :hover {
  color: var(--primary-color);
}
.footerWidgets .widget1 {
  width: 20%;
}
.footerWidgets .widget2 {
  width: 10%;
}
.footerWidgets .widget3 {
  width: 10%;
}
.footerWidgets .widget4 {
  width: 15%;
}
.footerWidgets .widget5 {
  width: 20%;
}
.footerWidgets .widget5 .soical ul li {
  cursor: pointer;
}
.footerWidgets .widget5 .soical ul {
  display: flex;
  gap: 0.7rem;
}
.footerWidgets .widget5 .subscribe {
  display: flex;
}
.footerWidgets .widget5 .subscribe input {
  height: 40px;
  margin-top: 20px;
  border-radius: 12px 0px 0px 12px;
  border: 2px solid var(--primary-color);
  background-color: transparent;
  border-right: none;
  padding-left: 12px;
  color: var(--color-grey);
  outline: none;
}
.footerWidgets .widget5 .subscribe button {
  border-radius: 0px 12px 12px 0px;
  height: 46px;
  color: #f7f7f7;
  border-left: none;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  margin-top: 20px;
}
.footerWidgets .widget5 .subscribe button:hover {
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color) !important;
}

.fotterWrapper .footerCopy {
  text-align: center;
  color: #6b6b6b;
  width: 100%;
}

.footerWidgets {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-around;
  align-items: flex-start;
}

/* media query */
@media screen and (max-width:900px) {
  .navItems,.navSocial{
    display: none;
  }
  .sideWrapper{
    display: block;
  }
  .navbar{
    padding: 0.5rem 0rem;
  }
 .navbar .nav{
    padding: 0rem 1rem;
  }
}
@media screen and (max-width:1090px) {
  .footerWidgets{
   flex-direction: column !important;
   width: 100%;
  }
  .footerWidgets .widget1,.widget2,.widget3,.widget4{
    /* flex-direction: column !important; */
    width: 100% !important;
   }
   .footerWidgets .widget1,.widget2,.widget3,.widget4{
    /* flex-direction: column !important; */
    width: 100% !important;
   }
   
}
@media screen and (max-width:500px) {
  .fotterWrapper{
    padding: 1rem !important;
  }
}
@media screen and (max-width:1024px) {
  .contentWrapper{
    flex-wrap: wrap;
  }
  .textWrapper{
    width: 80% !important;
  }
}
@media screen and (max-width:768px) {
  .imageWrapper{
    width: 80% !important;
  }
  .textWrapper{
    width: 100% !important;
  }
}
@media screen and (max-width:600px) {
  .imageWrapper{
    width: 100% !important;
  }
}