/* Home Page */

/* Landing Section */

.landingSection {
  width: 100%;
  background: url("./assets/bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landingSection .landing {
  display: flex;
  padding: 4rem;
  height: 500px;
  justify-content: space-around;
}
.landingSection .landing .landingContent {
  width: 50%;
}
.landingSection .landing .landingContent h1 {
  font-family: var(--primary-font);
  font-style: normal;
  display: inline-block;
  color: #f7f7f7;
  font-weight: 600;
  font-size: 50px;
}
.landingSection .landing .landingContent h1 span {
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.137), 0 0 30px rgba(0, 0, 0, 0.137),
    0 0 40px rgba(0, 0, 0, 0.137), 0 0 50px rgba(0, 0, 0, 0.137),
    0 0 60px rgba(0, 0, 0, 0.137), 0 0 70px rgba(0, 0, 0, 0.137);
}
.landingSection .landing .landingContent p {
  max-width: 420px;
  font-size: 14px;
  margin-top: -20px;
  display: inline-block;
  font-family: var(--secondary-font);
  text-align: justify;
}
.landingSection .landing .landingContent .buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.landingSection .landing .landingContent .buttons .btn {
  width: 120px;
  border-radius: 6px;
  border: none;
  font-family: var(--primary-font);
  font-weight: bold;
  filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.25));
  height: 40px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.landingSection .landing .landingContent .buttons .btn:hover {
  transition: all ease-in-out 0.3s;
  background: linear-gradient(#ff40b2, #fb6bc0, #f063b7);
  color: #f7f7f7;
}
.landingSection .landing .landingContent .buttons .hire {
  color: #f7f7f7;
  background-color: #ff40b2;
  background: linear-gradient(#ff40b2, #fb6bc0, #f063b7);
  filter: drop-shadow(4px 4px 15px rgba(242, 8, 147, 0.22));
}
.landingSection .landing .landingContent .buttons .hire:hover {
  transition: all ease-in-out 0.3s;
  background: #f0f0f0;
  color: #020202;
}
.landingSection .landing .landingImage {
  width: 50%;
  position: relative;
  animation-name: imageAnimation;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}
@keyframes imageAnimation {
  0%,
  100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
}
.landingSection .landing .landingImage img {
  width: 100%;
  filter: drop-shadow(0px 14px 250px rgba(0, 173, 239, 0.4));
  /* box-shadow: 10px 10px 10px rgba(0, 173, 239, 0.4); */
}

/* landing responsive */
@media screen and (max-width: 1000px) {
  .landingImage {
    display: none;
  }
  .landingSection .landing .landingContent {
    width: 100%;
  }
  .landingSection .landing .landingContent p {
    display: block;
    width: auto;
  }
  .keyServices {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    margin-top: -3rem;
  }
  .faqMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.3rem !important;
    margin-top: -3rem;
  }
  .faqs {
    padding: 0px !important;
  }
}
@media screen and (max-width: 600px) {
  .landingSection .landing {
    padding: 1rem 1rem 4rem 1rem;
  }
  .serviceItem {
    min-width: 250px !important;
    max-width: 320px !important;
  }
}
@media screen and (max-width: 445px) {
  .landingSection .landing {
    padding: 1rem 1rem 8rem 1rem;
  }
  .faqs .faq .faq-question {
    font-size: 12px !important;
  }
  .faqs .faq .faq-answer {
    font-size: 10px !important;
  }
  .itStaffing .itStaffingWrapper h1 {
    font-size: 26px !important;
  }
  .itStaffing .itStaffingWrapper p {
    font-size: 12px !important;
  }
  .contactSection {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding: 1.7rem !important;
    margin-top: -3rem;
  }
  .keyServices {
    padding: 0.7rem;
  }
}
@media screen and (max-width: 375px) {
  .landingSection .landing {
    padding: 1rem 1rem 16rem 1rem;
  }
}
@media screen and (max-width: 320px) {
  .landingSection .landing {
    padding: 1rem 1rem 16rem 1rem;
  }
  .landingSection .landing .landingContent h1 {
    font-family: var(--primary-font);
    font-style: normal;
    display: inline-block;
    color: #f7f7f7;
    font-weight: 600;
    font-size: 44px;
  }
}

/* key services */

.keyServices {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  margin-top: -3rem;
}
.keyServices .heading {
  position: relative;
}
.keyServices .heading h1 {
  margin-bottom: 3rem;
  font-family: var(--primary-font);
  font-size: 32px;
  color: var(--primary-color);
  font-weight: 800;
}
.Services {
  width: 100%;
  position: relative;
}
.serviceScroll {
  width: 100%;
}
#serviceCards {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.3rem;
}

.serviceItem {
  max-width: 200px;
  width: 100%;
  transition: all ease-in-out 0.3s;
  padding: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 270px;
  margin-bottom: 1rem;
  flex-direction: column;
  background: #ffffff;
  transition: all ease-in-out 0.3s;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}
.serviceItem:hover {
  transform: translateY(-10px);
}
.serviceItem:hover .serviceHeading h1 {
  transition: all ease-in-out 0.3s;
  color: var(--secondary-color) !important;
}
.serviceItem .itemCount {
  position: absolute;
  top: 10px;
  right: 20px;
  color: var(--color-grey);
  font-size: 12px;
  font-family: var(--secondary-font);
}

.serviceItem .serviceImage img {
  width: 60px;
}
.serviceItem .serviceHeading h1 {
  font-family: var(--primary-font);
  font-size: 16px;
  cursor: default;
  color: var(--primary-color);
  font-weight: 800;
  padding-top: 10px;
}
.serviceItem .serviceText p {
  font-family: var(--secondary-font);
  font-size: 12px;
  cursor: default;
  color: var(--color-grey);
  text-align: justify;
}

.moreService p {
  font-family: var(--primary-font);
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 700;
}

/* why us Section */

.whyUs {
  padding: 3rem;
  position: relative;
}
.backgroundEllipseLeft {
  position: absolute;
  width: 766px;
  height: 766px;
  left: -219px;
  top: 1px;
  background: rgba(0, 171, 239, 0.411);
  filter: blur(494px);
}
.contentSection {
  width: 50%;
  position: relative;
}

.questonMark::after {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -16px;
  margin-left: -10px;
  font-size: 12px;
  content: url("assets/content.jpg");
}
.whyUsMain {
  /* width: 100%; */
  display: flex;
  gap: 3rem;
  justify-content: space-around;
  align-items: center;
}
.whyUsMain::after {
  position: absolute;
  content: "";
  background: rgba(0, 171, 239, 0.918);
  filter: blur(300px);
  width: 100%;
  top: 600px;
  height: 120px;
}
.whyusDotted {
  position: relative;
  overflow: hidden;
  width: 500px;
  padding: 1rem;
  text-align: justify;
}
.whyusDotted::before {
  content: "";
  position: absolute;
  border-spacing: 100px !important ;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  border: 14px dashed #00adef;
}
.whyusContent {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  background: rgba(0, 173, 239, 0.32);
}

.whyusContent {
  font-family: var(--primary-font);
}

.whyusContent .headingMain h1 {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: -10px;
}
.whyusContent .contentMain p {
  line-height: 1.7rem;
  font-family: var(--secondary-font);
  font-weight: 400;
  color: #1a1a1a;
  width: 350px;
}

.whyUsImage {
  width: 50%;
  position: relative;
}
.whyUsImage img {
  width: 90%;
  animation-name: sectionAmimation;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

@keyframes sectionAmimation {
  0%,
  100% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0px);
  }
}

/* technology Section */

.technology {
  padding: 3rem;
  position: relative;
}
.backgroundEllipseRight {
  position: absolute;
  width: 100px;
  height: 100px;
  right: 100px;
  overflow: hidden;
  top: 1px;
  background: rgba(0, 171, 239, 0.411);
  filter: blur(494px);
}
.contentSection {
  width: 50%;
  position: relative;
}

.questonMark::after {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -16px;
  margin-left: -10px;
  font-size: 12px;
  content: url("assets/content.jpg");
}
.technologyMain {
  display: flex;
  flex-direction: row-reverse;
  gap: 3rem;
  justify-content: space-around;
  align-items: center;
}
.technologyDotted {
  position: relative;
  overflow: hidden;
  width: 500px;
  padding: 1rem;
  text-align: justify;
}
.technologyDotted::before {
  content: "";
  position: absolute;
  border-spacing: 100px !important ;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  border: 14px dashed rgba(235, 25, 150, 0.35);
}
.technologyContent {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  gap: 1rem;
  flex-direction: column;
  background: rgba(235, 25, 150, 0.35);
}

.technologyContent {
  font-family: var(--primary-font);
}
.technologyContent .contentMain {
  max-width: 400px;
}
.technologyContent .headingMain h1 {
  font-weight: 700;
  /* color: ; */
  font-size: 30px;
  margin-bottom: -10px;
}
.technologyContent .contentMain p {
  line-height: 1.7rem;
  font-family: var(--secondary-font);
  font-weight: 400;
  color: #1a1a1a;
  width: 350px;
}

.technologyImage {
  width: 50%;
  position: relative;
}
.technologyImage img {
  width: 90%;
  animation-name: sectionAmimation;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

.pattern {
  position: absolute;
  top: -50px;
  left: -20px;
  width: 120px;
  z-index: -1111111;
}
.pattern1 {
  position: absolute;
  top: -50px;
  left: -20px;
  width: 120px;
  z-index: -1111111;
}

@media screen and (max-width: 1024px) {
  .whyUsMain {
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }
  .technologyMain {
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }
  .whyUsImage img {
    width: 100%;
  }
  .whyusDotted {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 1rem;
    text-align: justify;
  }
  .technologyDotted {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 1rem;
    text-align: justify;
  }
  .whyusContent .contentMain p {
    line-height: 1.7rem;
    font-family: var(--secondary-font);
    font-weight: 400;
    color: #1a1a1a;
    width: auto;
  }
  .technologyContent .contentMain p {
    line-height: 1.7rem;
    font-family: var(--secondary-font);
    font-weight: 400;
    color: #1a1a1a;
    width: auto !important;
  }
  .contentSection {
    width: 60%;
    position: relative;
  }
  .contactMain {
    flex-wrap: wrap;
  }
  .technologyContent {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: none;
    gap: 1rem;
    flex-direction: column;
    background: rgba(235, 25, 150, 0.35);
  }
}
@media screen and (max-width: 768px) {
  .whyUsImage {
    width: 80%;
    position: relative;
  }
  .technologyImage {
    width: 80%;
    position: relative;
  }
  .whyUsImage img {
    width: 100%;
  }
  .whyusDotted {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 1rem;
    text-align: justify;
  }
  .technologyDotted {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 1rem;
    text-align: justify;
  }
  .whyusContent .contentMain p {
    line-height: 1.7rem;
    font-family: var(--secondary-font);
    font-weight: 400;
    color: #1a1a1a;
    width: auto;
  }
  .technologyContent .contentMain p {
    line-height: 1.7rem;
    font-family: var(--secondary-font);
    font-weight: 400;
    color: #1a1a1a;
    width: auto;
  }
  .contentSection {
    width: 100%;
    position: relative;
  }
}
@media screen and (max-width: 500px) {
  .whyusContent .headingMain h1 {
    font-weight: 700;
    /* color: var(--primary-color); */
    font-size: 22px;
    margin-bottom: -10px;
  }
  .technologyContent .headingMain h1 {
    font-weight: 700;
    /* color: var(--primary-color); */
    font-size: 22px;
    margin-bottom: -10px;
  }
  .technologyImage {
    width: 90%;
    position: relative;
  }
  .whyusContent .contentMain p {
    line-height: 1rem;
    font-family: var(--secondary-font);
    font-weight: 400;
    color: #1a1a1a;
    font-size: 10px;
    width: auto;
  }
  .technologyContent .contentMain p {
    line-height: 1rem;
    font-family: var(--secondary-font);
    font-weight: 400;
    color: #1a1a1a;
    font-size: 10px;
    width: auto;
  }
  .contentSection .pattern1 {
    width: 80px !important;
    top: -30px;
    left: -20px;
  }
  .contentSection .pattern {
    width: 80px !important;
    top: -30px;
    left: -20px;
  }
  .whyUs {
    padding: 1rem;
    margin-bottom: 3rem;
  }
  .whyusDotted {
    padding: 0.3rem;
  }
  .technologyDotted {
    padding: 0.3rem;
  }
  .technology {
    margin-bottom: 3rem;
    padding: 1rem;
  }
}
/* faq secion */
.faqMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  margin-top: -3rem;
}
.faqMain.heading {
  position: relative;
}
.faqMain .heading h1 {
  margin-bottom: 3rem;
  font-family: var(--primary-font);
  font-size: 32px;
  color: var(--primary-color);
  font-weight: 800;
}
.faqMain .heading h1::after {
  position: absolute;
  content: "";
  width: 60px;
  margin-top: 40px;
  margin-left: -40px;
  height: 4px;
  border-radius: 12px;
  background-color: var(--secondary-color);
}
.faqs {
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 12px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: rgb(228, 228, 228);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 17px;
  font-weight: 600;
  padding-right: 80px;
  font-family: var(--primary-font);
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "\1F847";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  font-size: 14px;
  color: var(--color-grey);
  font-family: var(--secondary-font);
  transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "\2716";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

/* it staffing */

.itStaffing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 400px;
  height: auto;
  background: url("./assets/itStaff.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  flex-direction: column;
  position: relative;
  margin-bottom: 3rem;
}
.itStaffingWrapper {
  position: relative;
  z-index: 11;
}
.itStaffing::after {
  content: "";
  background-color: #000000c7;
  min-height: 400px;
  width: 100%;
  position: absolute;
}
.itStaffing .itStaffingWrapper {
  /* margin:4rem; */
  z-index: 11;
  position: relative;
  max-width: 800px;
  padding: 12px;
}

.itStaffing .itStaffingWrapper h1 {
  color: rgba(255, 255, 255, 0.979);
  font-family: var(--primary-font);
  font-weight: 700;
  letter-spacing: 0.3rem;
  font-size: 34px;
  text-align: center;
  /* width: 500px; */
}
.itStaffing .itStaffingWrapper p {
  color: rgba(255, 255, 255, 0.979);
  font-family: var(--secondary-font);
  font-weight: 400;
  letter-spacing: 0.2rem;
  font-size: 16px;
  text-align: center;
}

.itStaffing .itStaffingWrapper center button {
  width: 120px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-family: var(--primary-font);
  font-weight: bold;
  filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.25));
  height: 40px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.itStaffing .itStaffingWrapper center button:hover {
  background: linear-gradient(#ff40b2, #fb6bc0, #f063b7);
  color: #f7f7f7;
}
/* Contact Section  */
.contactSection {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding: 3rem;
  margin-top: -3rem;
}
.contactSection::after {
  position: absolute;
  content: "";
  z-index: -1;
  background: rgba(0, 171, 239, 0.541);
  filter: blur(190px);
  width: 100%;
  top: 300px;
  height: 120px;
}
.contactSection.heading {
  position: relative;
}
.contactImage {
  width: 100%;
}
.contactImage img {
  width: 100%;
}
.contactContent {
  width: 100%;
}
.contactSection .heading h1 {
  margin-bottom: 3rem;
  font-family: var(--primary-font);
  font-size: 32px;
  color: var(--primary-color);
  font-weight: 800;
}
.contactSection .heading h1::after {
  position: absolute;
  content: "";
  width: 120px;
  margin-top: 40px;
  margin-left: -90px;
  height: 4px;
  border-radius: 12px;
  background-color: var(--secondary-color);
}

.contactMain {
  display: flex;
  width: 100%;
  gap: 2rem;
  justify-content: space-around;
}
.hireForm {
  display: block;
  width: 100%;
  justify-content: space-around;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  /* align-items: center; */
  width: 100%;
}
.inputWrapper div input,
select {
  width: 100%;
  height: 35px;
  font-family: var(--secondary-font);
  border-radius: 6px;
  padding-left: 12px;
  outline: none;
  border: none;
  background-color: #6460aa1e;
}
.inputWrapper div textarea {
  width: 100%;
  background-color: #6460aa1e;
  border: none;
  outline: none;
  height: 80px;
  padding-top: 12px;
  padding-left: 12px;
  resize: none;
  border-radius: 6px;
  font-family: var(--secondary-font);
}
.formSectionSubmit {
  width: 120px;
  border-radius: 6px;
  border: none;
  font-family: var(--primary-font);
  font-weight: bold;
  filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.25));
  height: 40px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  margin-top: 1rem;
  color: #f7f7f7;
  background-color: #ff40b2;
  background: linear-gradient(#ff40b2, #fb6bc0, #f063b7);
  filter: drop-shadow(4px 4px 15px rgba(242, 8, 147, 0.22));
}
.formdropdown {
  display: flex;
  gap: 1rem;
}
/* About Page */

/* about Landing */

.aboutPage {
  min-height: auto;
  width: 100%;
}
.aboutPage .aboutLanding {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-self: center;
  align-items: center;
  background: url("./assets/about\ us.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.aboutPage .aboutLanding::after {
  position: absolute;
  content: "";
  min-height: 90vh;
  height: 100vh;
  width: 100%;
  background-color: #00abef70;
}
.aboutPage .aboutLanding .aboutLandingIntro {
  position: relative;
  z-index: 1;
  width: 500px;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}
.aboutPage .aboutLanding .aboutLandingIntro h1 {
  font-size: 48px;
  font-family: var(--primary-font);
  padding-top: 12px;
  color: #ffff;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.137),
    0 0 30px rgba(255, 255, 255, 0.137), 0 0 40px rgba(255, 255, 255, 0.137),
    0 0 50px rgba(255, 255, 255, 0.137), 0 0 60px rgba(255, 255, 255, 0.137),
    0 0 70px rgba(255, 255, 255, 0.137);
}
.aboutPage .aboutLanding .aboutLandingIntro p {
  font-size: 16px;
  margin-top: -12px;
  font-weight: 400;
  text-align: justify;
  color: #f8f8f8;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.137),
    0 0 30px rgba(255, 255, 255, 0.137), 0 0 40px rgba(255, 255, 255, 0.137),
    0 0 50px rgba(255, 255, 255, 0.137), 0 0 60px rgba(255, 255, 255, 0.137),
    0 0 70px rgba(255, 255, 255, 0.137);
  font-family: var(--secondary-font);
}
.aboutPage .aboutLanding .aboutLandingIntro .breadCrum {
  position: absolute;
}
.aboutPage .aboutLanding .aboutLandingIntro .breadCrum a {
  color: #f063b7;
  font-weight: 500;
  transition: all ease-in-out 0.3s;
}
.aboutPage .aboutLanding .aboutLandingIntro .breadCrum a:hover {
  color: #f0f0f0;
}
.aboutPage .aboutLanding .aboutLandingIntro .breadCrum a span {
  color: #f0f0f0;
}

/* services page */
/* About Page */

/* about Landing */

.servicePage {
  min-height: auto;
  width: 100%;
}
.servicePage .serviceLanding {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-self: center;
  align-items: center;
  background: url("./assets/what.jpg");
  background-position: center;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
}
.servicePage .serviceLanding::after {
  position: absolute;
  content: "";
  min-height: 90vh;
  width: 100%;
  background-color: #00abef70;
}
.servicePage .serviceLanding .serviceLandingIntro {
  position: relative;
  z-index: 1;
  width: 500px;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}
.servicePage .serviceLanding .serviceLandingIntro h1 {
  font-size: 48px;
  font-family: var(--primary-font);
  padding-top: 12px;
  letter-spacing: 1px;
  color: #ffff;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.137),
    0 0 30px rgba(255, 255, 255, 0.137), 0 0 40px rgba(255, 255, 255, 0.137),
    0 0 50px rgba(255, 255, 255, 0.137), 0 0 60px rgba(255, 255, 255, 0.137),
    0 0 70px rgba(255, 255, 255, 0.137);
}
.servicePage .serviceLanding .serviceLandingIntro p {
  font-size: 16px;
  margin-top: -12px;
  font-weight: 400;
  text-align: justify;
  color: #f8f8f8;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.137),
    0 0 30px rgba(255, 255, 255, 0.137), 0 0 40px rgba(255, 255, 255, 0.137),
    0 0 50px rgba(255, 255, 255, 0.137), 0 0 60px rgba(255, 255, 255, 0.137),
    0 0 70px rgba(255, 255, 255, 0.137);
  font-family: var(--secondary-font);
}
.servicePage .serviceLanding .serviceLandingIntro .breadCrum {
  position: absolute;
}
.servicePage .serviceLanding .serviceLandingIntro .breadCrum a {
  color: #f063b7;
  font-weight: 500;
  transition: all ease-in-out 0.3s;
}
.servicePage .serviceLanding .serviceLandingIntro .breadCrum a:hover {
  color: #f0f0f0;
}
.servicePage .serviceLanding .serviceLandingIntro .breadCrum a span {
  color: #f0f0f0;
}

/* staffing page */
.staffingPage {
  min-height: auto;
  width: 100%;
}
.staffingPage .staffingLanding {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-self: center;
  align-items: center;
  background: url("./assets/why.jpg");
  background-position: center;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 3rem;
}
.staffingPage .staffingLanding::after {
  position: absolute;
  content: "";
  min-height: 90vh;
  width: 100%;
  background-color: #00abef70;
}
.staffingPage .staffingLanding .staffingLandingIntro {
  position: relative;
  z-index: 1;
  width: 500px;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}
.staffingPage .staffingLanding .staffingLandingIntro h1 {
  font-size: 48px;
  font-family: var(--primary-font);
  padding-top: 12px;
  letter-spacing: 1px;
  color: #ffff;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.137),
    0 0 30px rgba(255, 255, 255, 0.137), 0 0 40px rgba(255, 255, 255, 0.137),
    0 0 50px rgba(255, 255, 255, 0.137), 0 0 60px rgba(255, 255, 255, 0.137),
    0 0 70px rgba(255, 255, 255, 0.137);
}
.staffingPage .staffingLanding .staffingLandingIntro p {
  font-size: 16px;
  margin-top: -12px;
  font-weight: 400;
  text-align: justify;
  color: #f8f8f8;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.137),
    0 0 30px rgba(255, 255, 255, 0.137), 0 0 40px rgba(255, 255, 255, 0.137),
    0 0 50px rgba(255, 255, 255, 0.137), 0 0 60px rgba(255, 255, 255, 0.137),
    0 0 70px rgba(255, 255, 255, 0.137);
  font-family: var(--secondary-font);
}
.staffingPage .staffingLanding .staffingLandingIntro .breadCrum {
  position: absolute;
}
.staffingPage .staffingLanding .staffingLandingIntro .breadCrum a {
  color: #f063b7;
  font-weight: 500;
  transition: all ease-in-out 0.3s;
}
.staffingPage .staffingLanding .staffingLandingIntro .breadCrum a:hover {
  color: #f0f0f0;
}
.staffingPage .staffingLanding .staffingLandingIntro .breadCrum a span {
  color: #f0f0f0;
}

/* contact page */

.contactPage {
  min-height: auto;
  width: 100%;
}
.contactPage .contactLanding {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-self: center;
  align-items: center;
  background: url("./assets/Contact.jpg");
  background-position: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.contactPage .contactLanding::after {
  position: absolute;
  content: "";
  height: 50vh;
  width: 100%;
  background-color: #00abef70;
}
.contactPage .contactLanding .contactLandingIntro {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem;
}
.contactPage .contactLanding .contactLandingIntro h1 {
  font-size: 48px;
  font-family: var(--primary-font);
  padding-top: 12px;
  color: #ffff;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.137),
    0 0 30px rgba(255, 255, 255, 0.137), 0 0 40px rgba(255, 255, 255, 0.137),
    0 0 50px rgba(255, 255, 255, 0.137), 0 0 60px rgba(255, 255, 255, 0.137),
    0 0 70px rgba(255, 255, 255, 0.137);
}
@media screen and (max-width: 380px) {
  .contactPage .contactLanding .contactLandingIntro h1 {
    font-size: 36px;
  }
}
.contactPage .contactLanding .contactLandingIntro .breadCrum {
  position: absolute;
}
.contactPage .contactLanding .contactLandingIntro .breadCrum a {
  color: #f063b7;
  font-weight: 500;
  transition: all ease-in-out 0.3s;
}
.contactPage .contactLanding .contactLandingIntro .breadCrum a:hover {
  color: #f0f0f0;
}
.contactPage .contactLanding .contactLandingIntro .breadCrum a span {
  color: #f0f0f0;
}

.contactPage .contactCard {
  margin: 3rem;
}
.contactCard .contactWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: 1rem;
  flex-direction: column;
}
.contactCard .contactWrapper .headoffice {
  font-family: var(--secondary-font);
  letter-spacing: 1px;
  padding: 0rem 4rem;
  border: 2px solid #6460aa2c;
  background-color: #6460aa2c;
  transition: all ease-in-out 0.3s;
  border-radius: 12px;
}
.contactCard .contactWrapper .headoffice h1 {
  color: var(--primary-color);
  font-weight: 600;
}
.contactCard .contactWrapper .headoffice div {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: -20px;
  align-items: center;
}
.contactCard .contactWrapper .headoffice p {
  font-size: 13px;
  letter-spacing: normal;
  color: #3b3b3b;
}
.contactCard .contactWrapper .branches {
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.contactCard .contactWrapper .branches .branchOffice {
  font-family: var(--secondary-font);
  letter-spacing: 1px;
  padding: 0.4rem 1.7rem;
  border: 2px solid #6460aa2c;
  background-color: #6460aa2c;
  transition: all ease-in-out 0.3s;
  border-radius: 12px;
}
.contactCard .contactWrapper .branchOffice:hover {
  border: 2px solid var(--secondary-color);
  background-color: #ffffff;
}
.contactCard .contactWrapper .headoffice:hover {
  border: 2px solid var(--primary-color);
  background-color: #ffffff;
}
.contactCard .contactWrapper .branches .branchOffice h2 {
  color: var(--secondary-color);
}
.contactCard .contactWrapper .branches .branchOffice div {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: -20px;
  align-items: center;
}
.contactCard .contactWrapper .branches .branchOffice p {
  font-size: 13px;
  letter-spacing: normal;
  color: #3b3b3b;
}

@media screen and (max-width: 500px) {
  .things50 {
    font-size: 18px;
  }
}

.ourTeamCard {
  transition: all ease-in-out 0.3s;
}
.ourTeamCard:hover {
  transform: translateY(-10px);
}

.itServ {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .itServ {
    flex-wrap: wrap-reverse;
  }
}
.itServ .imgServ {
  width: 100%;
}
.itServ .imgServ img {
  max-width: 400px;
  width: 100%;
}

.gallaryWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  gap: 0;
  padding: 0;
  flex-direction: column;
}
.gallaryWrapper .gallery1 img {
  width: 33.33%;
  opacity: 0.5;
  transition: all ease-in-out 0.3s;
}
.gallaryWrapper .gallery2 img {
  transition: all ease-in-out 0.3s;
  width: 33.33%;
  opacity: 0.5;
}
.gallaryWrapper .gallery1 img:hover {
  width: 33.33%;
  opacity: 1;
}
.gallaryWrapper .gallery2 img:hover {
  width: 33.33%;
  opacity: 1;
}

.gallery2,
.gallery1 {
  margin: 0;
  padding: 0;
  background-color: #000000;
}
